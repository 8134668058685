// eslint-disable-next-line import/prefer-default-export
export const getType = (v) => {
  if (v === null) {
    return null
  }
  if (v === undefined) {
    return 'undefined'
  }
  return v.constructor.name.toLowerCase()
}
