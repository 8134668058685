// Format string to currency
import { getType } from '../types';
import { normalizeNumber } from '../string';

export const formatCurrency = (value, locale = 'pt-BR', currency = 'BRL') => {
  if (getType(value) !== 'number') {
    throw new Error('Currency value must be a number');
  }
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })
    .format(value)
    .split(',')[0];
};

export function convertCurrencyToNumber(value) {
  return normalizeNumber(value);
}
