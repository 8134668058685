import React, {
  useEffect,
  useContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';
import { DataContext } from '../../data/DataProvider';
import {
  getAtividades,
  getUF,
  getCoberturasApolice,
  getPriceQuote,
  submitProposal,
} from '../../services/base-service';
import { formatCurrency } from '../../utils/currency';
import './index.scss';
import classesConstrucao from '../../data/classesConstrucao';
import MainHeader from '../../components/main-header/main-header';
import PageHeader from '../../components/page-header/page-header';
import Button from '../../components/button/button';
import MainFooter from '../../components/main-footer/main-footer';
import DefaultLayout from '../../components/layouts/default-layout';
import editIcon from '../../images/pen.svg';
import moment from 'moment';
import parse from 'html-react-parser';
import { Link } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { maskCNPJ } from '../../helpers/validation';
import { isEditValid } from '../../helpers/editQuotation';

const block = 'summary';
const Page = ({ location }) => {
  const {
    quotation,
    setQuotation,
    activities,
    setActivities,
    setOriginalQuotation,
    originalQuotation,
    setModal,
    brokerData,
  } = useContext(DataContext);
  const [isAtividades, setIsAtividades] = useState();
  const [uf, setUF] = useState();
  const [isEstado, setEstado] = useState();
  const [isEstadoCobranca, setEstadoCobranca] = useState();
  const [responseCoberturas, setResponseCoberturas] = useState();
  const [motor, setMotor] = useState();
  const [dateNow, setDateNow] = useState();

  useEffect(() => {
    if (!quotation || !Object.keys(quotation).length) return navigate('/');
  }, [quotation]);

  useEffect(() => {
    if (quotation?.printMode && responseCoberturas && motor) {
      setQuotation({
        ...quotation,
        printMode: false,
      });
      return window.print();
    }
  }, [quotation, setQuotation, responseCoberturas, motor]);

  useEffect(() => {
    if (quotation && quotation?.propostaId) {
      if (originalQuotation?.savedQuotation) {
        setModal({
          values: {
            headline: 'Os dados foram salvos com sucesso!',
            primaryButton: {
              label: 'Ok',
              handler: () => {},
            },
            dismissible: true,
          },
        });
      }
      return setOriginalQuotation({ ...quotation });
    }
  }, [
    quotation,
    setOriginalQuotation,
    setModal,
    originalQuotation?.savedQuotation,
    originalQuotation?.editMode,
  ]);

  async function getMotor(obj) {
    let response = await getPriceQuote(obj);
    if (response && response.length) setMotor(response);
  }

  const construcaoItem = useMemo(() => {
    if (quotation?.avaliacaoRisco?.classeConstrucao) {
      let objectConstrucao = classesConstrucao.find(
        (item) => item.value === quotation?.avaliacaoRisco?.classeConstrucao
      );

      if (objectConstrucao) {
        return objectConstrucao;
      }
    } else {
      return null;
    }
  }, [quotation]);

  useEffect(() => {
    getMotor({ ...quotation });
    moment.locale('pt-br');
    const dayNow = moment().format('DD/MM/YYYY HH:mm');
    setDateNow(dayNow);
  }, [quotation]);

  const parcelamentoItems = useMemo(() => {
    if (motor) {
      return motor?.map((item, key) => (
        <div key={key} className={`${block}--row`}>
          <div className={`${block}--item-full`}>
            <p className={`${block}__text`}>
              <strong>{key + 1} x sem juros</strong> de R${' '}
              {item.parcela.toFixed(2).toString().replace('.', ',')} = R${' '}
              {item.premioTotal.toFixed(2).toString().replace('.', ',')}
            </p>
          </div>
        </div>
      ));
    } else {
      return (
        <div className={`${block}--row`}>
          <div className={`${block}--item-3`}>
            <p className={`${block}__text`}>Parcelas não encontradas</p>
          </div>
        </div>
      );
    }
  }, [motor]);

  const downloadHandler = useCallback(() => {
    trackCustomEvent({
      category: 'Download da carta de cotação',
      action: 'Click',
    });
    window.print();
  }, []);

  const onSubmitProposal = useCallback(async () => {
    let response = await submitProposal(quotation?.quotationNumber, quotation);
    if (!response.error) {
      navigate(
        `${process.env.GATSBY_URL_DIGITAL}/protector/ferramentas/propostas`
      );
    } else {
      const error = response.error.replace(/\n/g, '<br />');
      setModal({
        values: {
          headline: 'Ops! ',
          message: parse(error),
          primaryButton: {
            label: 'Ok',
            handler: () => {},
          },
        },
      });
    }
  }, [quotation, setModal]);

  const backButtonHandler = useCallback(() => {
    trackCustomEvent({
      category: 'Botão voltar',
      action: 'Click',
    });
    navigate('/finalizar-proposta');
  }, []);
  useMemo(() => {
    async function fetchData() {
      if (!activities) {
        try {
          const atividades = await getAtividades();
          setActivities(atividades);
        } catch (error) {}
      }
      try {
        const estados = await getUF();
        setUF(estados);
      } catch (error) {}
    }
    if (!activities || !uf) fetchData();
  }, [activities, uf, setActivities, setUF]);

  useEffect(() => {
    async function fetchData() {
      const listCoberturas = await getCoberturasApolice(
        quotation?.dadosEmpresa?.atividade,
        quotation?.avaliacaoRisco?.valorRiscoTotal
          ? quotation.avaliacaoRisco.valorRiscoTotal
          : 0
      );
      setResponseCoberturas(listCoberturas);
    }
    fetchData();
  }, [quotation]);

  useEffect(() => {
    if (
      quotation &&
      quotation?.dadosEmpresa?.enderecoLocalRisco?.estado &&
      Array.isArray(uf)
    ) {
      let labelUF = uf.find(
        (item) =>
          item.label === quotation?.dadosEmpresa?.enderecoLocalRisco?.estado ||
          item.value === quotation?.dadosEmpresa?.enderecoLocalRisco?.estado
      );

      if (labelUF) {
        setEstado(labelUF.label);
      }
    }

    if (
      quotation &&
      quotation.dadosEmpresa?.enderecoCobranca?.estado &&
      Array.isArray(uf)
    ) {
      let labelUFCobranca = uf.find(
        (item) =>
          item.label === quotation.dadosEmpresa?.enderecoCobranca?.estado ||
          item.value === quotation.dadosEmpresa?.enderecoCobranca?.estado
      );

      if (labelUFCobranca) {
        setEstadoCobranca(labelUFCobranca.label);
      }
    }
  }, [quotation, uf]);

  useEffect(() => {
    if (quotation && quotation?.dadosEmpresa?.atividade && activities) {
      let labelAtividade = activities.find(
        (item) => item.value === quotation?.dadosEmpresa?.atividade
      );

      if (labelAtividade) {
        setIsAtividades(labelAtividade.label);
      }
    }
  }, [quotation, activities]);

  const titlePage = useMemo(() => {
    if (quotation?.propostaId && !quotation?.printMode) {
      return 'Editar Cotação';
    }
    return 'Carta de Cotação';
  }, [quotation]);

  const maskedCustomerDocument = (document) => {
    return maskCNPJ(document) || 'Indisponível';
  };

  const visibleBlockAssessoria = useMemo(() => {
    if (
      brokerData?.dadosBroker?.documento === quotation?.dadosBroker?.brokerId
    ) {
      return false;
    }
    return true;
  }, [brokerData, quotation]);

  return (
    <DefaultLayout currentPagePath={location.pathname}>
      <Helmet>
        <title>{titlePage}</title>
      </Helmet>
      <main className={block}>
        <MainHeader />
        <PageHeader
          title={titlePage}
          backButtonHandler={() => navigate('/finalizar-proposta')}
          editMode={!!quotation?.propostaId}
          description={
            <div className={`${block}__page-header--description`}>
              <span>
                Essa cotação tem validade de 15 dias a partir de
                {!quotation?.propostaId &&
                  ` ${dateNow?.split(' ')[0]} às ${dateNow?.split(' ')[1]}`}
                {quotation?.propostaId &&
                  ` ${quotation?.creationDate.split('-')[2].split('T')[0]}/${
                    quotation?.creationDate.split('-')[1]
                  }/${quotation?.creationDate.split('-')[0]} às ${`${
                    quotation?.creationDate.split('T')[1].split(':')[0]
                  }:${quotation?.creationDate.split(':')[1]}`}`}
              </span>
              {isEditValid(quotation) && !quotation.isProposal && (
                <Button
                  className={`button_cta`}
                  theme={'tertiary'}
                  type="button"
                  onClick={onSubmitProposal}
                >
                  Enviar proposta
                </Button>
              )}
            </div>
          }
          breadCrumbs={[
            {
              label: 'Início',
              path: '/',
            },
            {
              label: 'CNPJ',
              path: '/cnpj',
            },
            {
              label: 'Dados da empresa',
              path: '/dados-da-empresa',
            },
            {
              label: 'Informações do seguro',
              path: '/informacoes-do-seguro',
            },
            {
              label: 'Avaliação de risco',
              path: '/avaliacao-de-risco',
            },
            {
              label: 'Sistemas protecionais',
              path: '/sistemas-protecionais',
            },
            {
              label: 'Sinistralidade',
              path: '/sinistralidade',
            },
            {
              label: 'Exposição política',
              path: '/exposicao-politica',
            },
            {
              label: 'Coberturas',
              path: '/coberturas',
            },
            {
              label: 'Finalizar proposta',
              path: '/finalizar-proposta',
            },
          ]}
          fixed
          hide={true}
          campaignHide={true}
        />
        <div className={`container--large ${block}--fixed`}>
          <div className={`${block}__wrapper`}>
            {visibleBlockAssessoria && (
              <div className="dados-assesoria">
                <div className={`${block}__titleFormGroup`}>
                  <span>Dados da assessoria</span>
                </div>
                <div className={`${block}__formGroup`}>
                  <div className={`${block}--row`}>
                    <div className={`${block}--item-3`}>
                      <p className={`${block}__text`}>
                        <strong>CNPJ: </strong>
                        {maskedCustomerDocument(
                          brokerData?.dadosBroker?.documento
                        )}
                      </p>
                    </div>
                    <div className={`${block}--item-3`}>
                      <p className={`${block}__text`}>
                        <strong>Assessoria: </strong>
                        {brokerData?.dadosBroker?.nome || 'Indisponível'}
                      </p>
                    </div>
                    <div className={`${block}--item-3`}>
                      <p className={`${block}__text`}>
                        <strong>E-mail: </strong>
                        {brokerData?.dadosBroker?.email || 'Indisponível'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className={`${block}__titleFormGroup`}>
              <span>
                {brokerData?.dadosBroker?.corretoras && !visibleBlockAssessoria
                  ? 'Dados da assessoria'
                  : 'Dados da corretora'}
              </span>
            </div>
            <div className={`${block}__formGroup`}>
              <div className={`${block}--row`}>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>CNPJ: </strong>
                    {maskedCustomerDocument(quotation?.dadosBroker?.brokerId)}
                  </p>
                </div>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>Corretora: </strong>
                    {quotation?.dadosBroker?.nome || 'Indisponível'}
                  </p>
                </div>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>E-mail: </strong>
                    {quotation?.dadosBroker?.email || 'Indisponível'}
                  </p>
                </div>
              </div>
            </div>
            <div className={`${block}__titleFormGroup`}>
              <span>Dados da empresa</span>
              {isEditValid(quotation) && (
                <Link className="icon-edit" to="/dados-da-empresa">
                  <img alt="" src={editIcon} />
                </Link>
              )}
            </div>
            <div className={`${block}__formGroup`}>
              <div className={`${block}--row`}>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>CNPJ: </strong>
                    {maskedCustomerDocument(quotation?.dadosEmpresa?.cnpj)}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Razão social: </strong>
                    {quotation?.dadosEmpresa?.razaoSocial}
                  </p>
                </div>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>Nome fantasia: </strong>
                    {quotation?.dadosEmpresa?.nomeFantasia}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>E-mail: </strong>
                    {quotation?.dadosEmpresa?.email}
                  </p>
                </div>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>Telefone: </strong>
                    {quotation?.dadosEmpresa?.telefonePrincipal}
                  </p>
                </div>
              </div>
            </div>

            <div className={`${block}__titleFormGroup`}>
              <span>Dados do local de risco</span>
              {isEditValid(quotation) && (
                <Link className="icon-edit" to="/dados-da-empresa">
                  <img alt="" src={editIcon} />
                </Link>
              )}
            </div>
            <div className={`${block}__formGroup`}>
              <div className={`${block}--row`}>
                <div className={`${block}--item-full`}>
                  <p className={`${block}__text`}>
                    <strong>Atividade exercida no local de risco: </strong>
                    {isAtividades}
                  </p>
                </div>
              </div>
              <div className={`${block}--row`}>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>CEP: </strong>
                    {quotation?.dadosEmpresa?.enderecoLocalRisco?.cep}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Logradouro: </strong>
                    {quotation?.dadosEmpresa?.enderecoLocalRisco?.logradouro}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Número: </strong>
                    {quotation?.dadosEmpresa?.enderecoLocalRisco?.numero}
                  </p>
                </div>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>Complemento: </strong>
                    {quotation?.dadosEmpresa?.enderecoLocalRisco?.complemento}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Bairro: </strong>
                    {quotation?.dadosEmpresa?.enderecoLocalRisco?.bairro}
                  </p>
                </div>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>Cidade: </strong>
                    {quotation?.dadosEmpresa?.enderecoLocalRisco?.cidade}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Estado: </strong>
                    {isEstado}
                  </p>
                </div>
              </div>
            </div>

            <div className={`${block}__titleFormGroup`}>
              <span>Endereço de cobrança</span>
              {isEditValid(quotation) && (
                <Link className="icon-edit" to="/dados-da-empresa">
                  <img alt="" src={editIcon} />
                </Link>
              )}
            </div>
            <div className={`${block}__formGroup`}>
              <div className={`${block}--row`}>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>Usar o mesmo endereço do local de risco: </strong>
                    {quotation?.dadosEmpresa?.enderecoCobrancaMesmoLocalRisco
                      ? 'Sim'
                      : 'Não'}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>CEP: </strong>
                    {quotation?.dadosEmpresa?.enderecoCobrancaMesmoLocalRisco
                      ? quotation?.dadosEmpresa?.enderecoLocalRisco?.cep
                      : quotation?.dadosEmpresa?.enderecoCobranca?.cep}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Logradouro: </strong>
                    {quotation?.dadosEmpresa?.enderecoCobrancaMesmoLocalRisco
                      ? quotation?.dadosEmpresa?.enderecoLocalRisco?.logradouro
                      : quotation?.dadosEmpresa?.enderecoCobranca?.logradouro}
                  </p>
                </div>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>Número: </strong>
                    {quotation?.dadosEmpresa?.enderecoCobrancaMesmoLocalRisco
                      ? quotation?.dadosEmpresa?.enderecoLocalRisco?.numero
                      : quotation?.dadosEmpresa?.enderecoCobranca?.numero}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Complemento: </strong>
                    {quotation?.dadosEmpresa?.enderecoCobrancaMesmoLocalRisco
                      ? quotation?.dadosEmpresa?.enderecoLocalRisco?.complemento
                      : quotation?.dadosEmpresa?.enderecoCobranca?.complemento}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Bairro: </strong>
                    {quotation?.dadosEmpresa?.enderecoCobrancaMesmoLocalRisco
                      ? quotation?.dadosEmpresa?.enderecoLocalRisco?.bairro
                      : quotation?.dadosEmpresa?.enderecoCobranca?.bairro}
                  </p>
                </div>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>Cidade: </strong>
                    {quotation?.dadosEmpresa?.enderecoCobrancaMesmoLocalRisco
                      ? quotation?.dadosEmpresa?.enderecoLocalRisco?.cidade
                      : quotation?.dadosEmpresa?.enderecoCobranca?.cidade}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Estado: </strong>
                    {quotation?.dadosEmpresa?.enderecoCobrancaMesmoLocalRisco
                      ? isEstado
                      : isEstadoCobranca}
                  </p>
                </div>
              </div>
            </div>

            <div className={`${block}__titleFormGroup`}>
              <span>Informações do seguro</span>
              {isEditValid(quotation) && (
                <Link className="icon-edit" to="/informacoes-do-seguro">
                  <img alt="" src={editIcon} />
                </Link>
              )}
            </div>
            <div className={`${block}__formGroup`}>
              <div className={`${block}--row`}>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>Tipo de Seguro: </strong>
                    {quotation?.informacoesSeguro?.tipoDeSeguro === 1
                      ? 'Novo'
                      : quotation?.informacoesSeguro?.tipoDeSeguro === 2
                      ? 'Renovação Akad'
                      : 'Renovação Congênere'}
                  </p>
                </div>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>Início da vigência: </strong>
                    {
                      quotation?.informacoesSeguro?.inicioVigenciaApolice
                        .split('-')[2]
                        .split('T')[0]
                    }
                    /
                    {
                      quotation?.informacoesSeguro?.inicioVigenciaApolice.split(
                        '-'
                      )[1]
                    }
                    /
                    {
                      quotation?.informacoesSeguro?.inicioVigenciaApolice.split(
                        '-'
                      )[0]
                    }
                  </p>
                </div>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>Fim da vigência: </strong>
                    {
                      quotation?.informacoesSeguro?.fimVigenciaApolice
                        .split('-')[2]
                        .split('T')[0]
                    }
                    /
                    {
                      quotation?.informacoesSeguro?.fimVigenciaApolice.split(
                        '-'
                      )[1]
                    }
                    /
                    {
                      quotation?.informacoesSeguro?.fimVigenciaApolice.split(
                        '-'
                      )[0]
                    }
                  </p>
                </div>
              </div>
            </div>

            <div className={`${block}__titleFormGroup`}>
              <span>Questionário de avaliação de risco</span>
              {isEditValid(quotation) && (
                <Link className="icon-edit" to="/avaliacao-de-risco">
                  <img alt="" src={editIcon} />
                </Link>
              )}
            </div>
            <div className={`${block}__formGroup`}>
              <div className={`${block}--row`}>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>Valor em risco total: </strong>
                    {formatCurrency(
                      parseInt(quotation?.avaliacaoRisco?.valorRiscoTotal)
                    )}
                    ,00
                  </p>
                  <div className={`${block}__text`}>
                    <strong>Tipo de construção: </strong>
                    {quotation?.avaliacaoRisco?.classeConstrucao > 2 ? (
                      'Classe de construção sem aceitação.'
                    ) : (
                      <span>{`${construcaoItem?.label}*`}</span>
                    )}
                  </div>
                </div>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>% de isopainel na construção: </strong>
                    {quotation?.avaliacaoRisco?.isopainelConstrucao
                      ? 'Acima de 25%'
                      : 'Abaixo de 25%'}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>É patrimônio histórico ou tombado? </strong>
                    {quotation?.avaliacaoRisco?.imovelTombado ? 'Sim' : 'Não'}
                  </p>
                </div>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>Imóvel desocupado: </strong>
                    {quotation?.avaliacaoRisco?.imovelDesocupado
                      ? 'Sim'
                      : 'Não'}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Localização do risco: </strong>
                    {quotation?.avaliacaoRisco?.localizacaoRisco === 3
                      ? 'Condomínio comercial'
                      : quotation?.avaliacaoRisco?.localizacaoRisco === 4
                      ? 'Shopping'
                      : quotation?.avaliacaoRisco?.localizacaoRisco === 5
                      ? 'Rua'
                      : 'Classe de construção sem aceitação.'}
                  </p>
                </div>
              </div>
              <div>
                {quotation?.avaliacaoRisco?.classeConstrucao > 2 ? (
                  'Classe de construção sem aceitação.'
                ) : (
                  <span className="description-box">{`*${construcaoItem?.description}`}</span>
                )}
              </div>
            </div>
            <div className={`${block}__titleFormGroup`}>
              <span>Sistemas protecionais</span>
              {isEditValid(quotation) && (
                <Link className="icon-edit" to="/sistemas-protecionais">
                  <img alt="" src={editIcon} />
                </Link>
              )}
            </div>
            <div className={`${block}__formGroup`}>
              <div className={`${block}--row`}>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>Alarme: </strong>
                    {quotation?.sistemaProtecional?.protecoes.find(
                      (item) => item === 9
                    )
                      ? 'Sim'
                      : 'Não'}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Brigada de incêndio: </strong>
                    {quotation?.sistemaProtecional?.protecoes.find(
                      (item) => item === 7
                    )
                      ? 'Sim'
                      : 'Não'}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Extintor: </strong>
                    {quotation?.sistemaProtecional?.protecoes.find(
                      (item) => item === 2
                    )
                      ? 'Sim'
                      : 'Não'}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Sprinklers: </strong>
                    {quotation?.sistemaProtecional?.protecoes.find(
                      (item) => item === 4
                    )
                      ? 'Sim'
                      : 'Não'}
                  </p>
                </div>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>Alarme de incêndio: </strong>
                    {quotation?.sistemaProtecional?.protecoes.find(
                      (item) => item === 6
                    )
                      ? 'Sim'
                      : 'Não'}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>CFTV: </strong>
                    {quotation?.sistemaProtecional?.protecoes.find(
                      (item) => item === 11
                    )
                      ? 'Sim'
                      : 'Não'}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Grades em janelas e portas: </strong>
                    {quotation?.sistemaProtecional?.protecoes.find(
                      (item) => item === 8
                    )
                      ? 'Sim'
                      : 'Não'}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Vigilância armada: </strong>
                    {quotation?.sistemaProtecional?.protecoes.find(
                      (item) => item === 13
                    )
                      ? 'Sim'
                      : 'Não'}
                  </p>
                </div>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>Alarme monitorado: </strong>
                    {quotation?.sistemaProtecional?.protecoes.find(
                      (item) => item === 10
                    )
                      ? 'Sim'
                      : 'Não'}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Detector de fumaça: </strong>
                    {quotation?.sistemaProtecional?.protecoes.find(
                      (item) => item === 5
                    )
                      ? 'Sim'
                      : 'Não'}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Hidrante: </strong>
                    {quotation?.sistemaProtecional?.protecoes.find(
                      (item) => item === 3
                    )
                      ? 'Sim'
                      : 'Não'}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Vigilância desarmada: </strong>
                    {quotation?.sistemaProtecional?.protecoes.find(
                      (item) => item === 12
                    )
                      ? 'Sim'
                      : 'Não'}
                  </p>
                </div>
              </div>
            </div>

            <div className={`${block}__titleFormGroup`}>
              <span>Sinistralidade</span>
              {isEditValid(quotation) && (
                <Link className="icon-edit" to="/sinistralidade">
                  <img alt="" src={editIcon} />
                </Link>
              )}
            </div>
            <div className={`${block}__formGroup`}>
              <div className={`${block}--row`}>
                <div className={`${block}--item-full`}>
                  <p className={`${block}__text`}>
                    <strong>
                      Houve algum sinistro maior do que R$ 50 mil nos últimos 12
                      meses ?
                    </strong>
                    {quotation?.sinistralidade?.sinistrosUltimosDozeMeses
                      ? ' Sim'
                      : ' Não'}
                  </p>
                  <p className={`${block}__text`}>
                    <strong>
                      Informe a quantidade de sinistros ocorridos na empresa nos
                      últimos 5 anos:{' '}
                    </strong>
                    {quotation?.sinistralidade?.sinistrosUltimosCincoAnos}
                  </p>
                </div>
              </div>
            </div>

            <div className={`${block}__titleFormGroup`}>
              <span>Pessoas politicamente expostas</span>
              {
                // Este item não pode ser editado
                /* {isEditValid(quotation) && (
                <Link className="icon-edit" to="/exposicao-politica">
                  <img alt="" src={editIcon} />
                </Link>
              )} */
              }
            </div>
            <div className={`${block}__formGroup`}>
              <div className={`${block}--row`}>
                <div className={`${block}--item-full`}>
                  <p className={`${block}__text`}>
                    <strong>Existem pessoas politicamente expostas:</strong>
                    {quotation?.exposicaoPolitica
                      ?.existePessoaPoliticamenteExposta
                      ? ' Sim'
                      : ' Não'}
                  </p>
                </div>
              </div>
            </div>

            <div className={`${block}__titleFormGroup`}>
              <span>Coberturas selecionadas</span>
              {isEditValid(quotation) && (
                <Link className="icon-edit" to="/coberturas">
                  <img alt="" src={editIcon} />
                </Link>
              )}
            </div>
            <div className={`${block}__formGroup`}>
              <div className={`${block}--row`}>
                <div className={`${block}--item-full`}>
                  {quotation?.coberturas?.map((item) => {
                    if (responseCoberturas) {
                      const dataCobertura = responseCoberturas.find(
                        ({ coberturaId }) => coberturaId === item.cobertura
                      );
                      return (
                        <p
                          key={dataCobertura.nome}
                          className={`${block}__text`}
                        >
                          <strong>{dataCobertura.nome}:</strong>{' '}
                          {formatCurrency(parseInt(item.valor))},00{' '}
                          <i> - Franquia de {dataCobertura.franquia}</i>
                        </p>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>

            <div className={`${block}__titleFormGroup`}>
              <span>Assistências 24 horas</span>
            </div>
            <div className={`${block}__formGroup`}>
              <div className={`${block}--row`}>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>Problemas hidráulicos</strong>
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Problemas elétricos</strong>
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Chaveiro</strong>
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Quebra de vidro</strong>
                  </p>
                </div>
                <div className={`${block}--item-3`}>
                  <p className={`${block}__text`}>
                    <strong>Limpeza</strong>
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Vigilância</strong>
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Desentupimento</strong>
                  </p>
                  <p className={`${block}__text`}>
                    <strong>Telhados</strong>
                  </p>
                </div>
              </div>
            </div>

            <div className={`${block}__titleFormGroup`}>
              <span>Opções de parcelamento</span>
              {isEditValid(quotation) && (
                <Link className="icon-edit" to="/finalizar-proposta">
                  <img alt="" src={editIcon} />
                </Link>
              )}
            </div>
            <div className={`${block}__formGroup`}>
              <div className={`${block}--row`}>
                <div className={`${block}--item-full`}>{parcelamentoItems}</div>
              </div>
            </div>

            <div className={`${block}__texto-legal`}>
              <p>
                A proposta de seguro será aceita no prazo de até 15 dias
                corridos, a partir do recebimento da proposta pela Seguradora.
              </p>
              <p>
                Em até 15 dias a apólice será emitida e enviada, substituindo a
                aceitação da proposta.
              </p>
              <p>
                Caso não haja uma manifestação de nossa parte, em até 15 dias
                corridos, os riscos apresentados na cotação podem ser assumidos.
              </p>
              <p>
                Podemos recusar a proposta caso não haja o cumprimento de todos
                os riscos apresentados na cotação.
              </p>
              <p>
                I - A aceitação da proposta de seguro está sujeita à análise do
                risco;
              </p>
              <p>
                II - O registro do produto é automático e não representa
                aprovação ou recomendação por parte da SUSEP; e
              </p>
              <p>
                III - O segurado poderá consultar a situação cadastral do
                corretor de seguros e da sociedade seguradora no sítio
                eletrônico{' '}
                <a
                  href="http://www.susep.gov.br"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.susep.gov.br
                </a>
                .
              </p>
              <p>
                O Segurado declara que ao fazer a proposta está ciente das
                referidas condições contratuais disponibilizadas no site da
                SUSEP, sob o nº de 5414.000340/2012-98, 15414.000478/2012-97,
                15414.901385/2013-62
              </p>
              <p>
                É responsabilidade do intermediário de seguro atender ao
                previsto na Resolução CNSP 382/20, no que se refere ao
                relacionamento com o cliente, especialmente sobre as informações
                que devem ser disponibilizadas antes da aquisição do produto de
                seguro, tendo sido prestadas todas as informações necessárias.
                Havendo alguma nova dúvida por parte do Proponente, o
                intermediário poderá ser diretamente contatado.” - Art 4º,
                inciso IV.
              </p>
              <p>
                A Akad Seguros trata o tema sobre privacidade e proteção dos
                dados pessoais com extrema seriedade e cautela. Para saber quais
                dados são tratados, para quais finalidades e de que forma
                fazemos os tratamentos, solicitamos que leia atentamente a nossa
                Política de Privacidade e Proteção de Dados, disponibilizada em
                nosso sítio eletrônico através do link:{' '}
                <a
                  href="https://akadseguros.com.br/politica-de-privacidade/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://akadseguros.com.br/politica-de-privacidade/
                </a>
                . Caso haja dúvidas sobre os termos ou dispositivos da nossa
                Política, favor entrar em contato com a Akad Seguros, através
                dos seguintes canais de comunicação: Central de Atendimento –
                0800 942 2746 e 4000 1246.
              </p>
            </div>
          </div>
        </div>
        <div className={`container`} align="center">
          {!quotation?.propostaId && (
            <Button
              className={`button_cta`}
              type="button"
              onClick={backButtonHandler}
              theme={'secondary'}
            >
              Voltar
            </Button>
          )}
          <Button
            className={`button_cta button--margin-left`}
            type="button"
            onClick={downloadHandler}
          >
            Download da carta de cotação
          </Button>
        </div>
        <MainFooter />
      </main>
    </DefaultLayout>
  );
};

export default Page;
